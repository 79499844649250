<template>
  <div class="body">
    <h1 class="text-secondary-1 fw-bold text-center mb-1">
      Create your new password
    </h1>
    <p class="text-primary-0 text-center fw-bold">Just follow the next steps</p>
    <form
      class="d-flex flex-column gap-2 mt-4"
      @submit.prevent="createPassword"
    >
      <div class="field">
        <password
          :feedback="false"
          name="password"
          placeholder="Password"
          toggleMask
          class="shadow-sm rounded-0"
          required
          v-model="fields.password"
          autocomplete="on"
        />
      </div>

      <div class="form-requirements mt-2" v-if="requirements.length > 0">
        <div
          class="requeriment d-flex gap-2 align-items-center mb-2"
          v-for="(req, index) in requirements"
          :key="index"
        >
          <span
            class="validate d-flex align-items-center justify-content-center"
            :class="req.isValidate ? 'validate-fill' : ''"
          >
            <i class="pi pi-check text-secondary-1 fw-bold"></i>
          </span>
          <span>{{ req.text }}</span>
        </div>
      </div>
      <div class="field">
        <password
          :feedback="false"
          name="password_confirmation"
          placeholder="Confirm your password"
          toggleMask
          class="shadow-sm rounded-0"
          required
          autocomplete="on"
          v-model="fields.password_confirmation"
        />
      </div>

      <button
        type="submit"
        class="btn text-white bg-primary-1 bg-primary-1 p-2 px-5 w-100 mt-4"
        :disabled="
          isCreatePassLoading || requirementsValidated < 4 || !isValidateToken
        "
      >
        Reset my password
      </button>
      <div class="d-flex gap-1 align-items-center justify-content-center">
        <p>Did you remember it?</p>
        <router-link :to="{ name: 'login' }">
          <button
            type="button"
            class="btn text-secondary-1 text-hover-secondary-1 p-2 px-2"
          >
            Login
          </button>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import useResetPassword from "@/modules/public/composables/useResetPassword";

export default {
  name: "CreatePasswordView",
  setup() {
    return {
      ...useResetPassword(),
    };
  },
};
</script>